<template>
  <div class="page-room-allotment-contract-detail">
    <header-contract-detail />

    <contract-detail-table
      :invoice-list="invoiceList"
      :pagination="pagination"
      :loading-invoice-list="loadingInvoiceList"
      :params="params"
      @emit-apply-filter="applyFilter"
      @reload-invoice-list="processingInvoiceListItems"
    />
  </div>
</template>

<script>
import roomAllotmentApi from '@admin_endpoints/room-allotment';

import HeaderContractDetail from '@admin_organisms/HeaderContractDetail';
import ContractDetailTable from '@admin_organisms/ContractDetailTable';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'RoomAllotmentContractDetail',

  components: {
    HeaderContractDetail,
    ContractDetailTable,
  },

  data() {
    return {
      loadingInvoiceList: false,
      pagination: {},
      invoiceList: [],
      params: {
        sort_by: 'scheduled_date',
        sort_dir: 'asc',
        paid_status: '',
        search: '',
        offset: 0,
      },
    };
  },

  computed: {
    ...mapState('note', ['modalState']),
    ...mapGetters(['xSelectedPropertyName']),

    contractId() {
      return this.$route.params.contractId;
    },
  },

  watch: {
    modalState: {
      handler(value) {
        if (value === 'note_saved') {
          this.processingInvoiceListItems();
        }
      },
    },
  },

  methods: {
    async getInvoiceListItems(query) {
      let result = {};
      try {
        result = await roomAllotmentApi.getContractDetailList(
          this.contractId,
          query
        );
      } catch (error) {
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async processingInvoiceListItems() {
      this.loadingInvoiceList = true;

      const query = `?${new URLSearchParams(this.params)}`;

      const response = await this.getInvoiceListItems(query);
      const { data } = response;

      this.pagination = data.pagination;

      this.invoiceList = data.data.data.map(item => ({
        invoiceId: item.invoice_id,
        invoiceNo: item.invoice_number,
        invoiceStatus: item.paid_status,
        voucherAmount: item.voucher_amount,
        voucherType: item.voucher_type,
        bill: item.amount,
        period: item.duration_unit,
        dueDate: item.scheduled_date,
        additionalCost: item.additional_costs,
        note: item.note,
        noteUpdatedBy: item.note_last_updated_by,
        noteUpdatedTime: item.note_last_updated_by?.date,
        showOptions: false,
      }));

      this.loadingInvoiceList = false;
    },

    applyFilter(payload) {
      this.params[payload.type] = payload.value;
      this.processingInvoiceListItems();
    },
  },

  created() {
    this.processingInvoiceListItems();
    if (this.xSelectedPropertyName) {
      this.$route.meta.breadcrumbs[1].name = this.xSelectedPropertyName;
    }
  },

  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumbs = [
      {
        name: 'Daftar Properti',
        url: '/',
      },
      {
        name: '...',
      },
      {
        name: 'Ketersediaan Kamar',
      },
      {
        name: 'Contract Detail',
      },
    ];
    next();
  },
};
</script>

<style lang="scss" src="./RoomAllotmentContractDetail.scss" scoped></style>
