import Api from '@admin/api/service';

const path = '/singgahsini/api/room-allotment/invoice/';

export default {
  getAdditionalCosts(invoiceId) {
    return Api.get(`${path + invoiceId}/additional-cost`);
  },
  postAdditionalCost(data) {
    return Api.post(`${path}additional-cost`, data);
  },
};
