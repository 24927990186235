import AdditionalCostApi from '@admin_endpoints/additionalCost';

export default {
  data() {
    return {
      isModalAdditionalCostOpen: false,
      loadingGetAdditionalCost: false,
      additionalCosts: [],
      selectedInvoiceId: null,
      selectedPaidStatus: '',
    };
  },

  methods: {
    openModalAddionalCost() {
      this.isModalAdditionalCostOpen = true;
    },

    async fetchingAdditionalCostData(invoiceId) {
      this.loadingGetAdditionalCost = true;

      try {
        const { data } = await AdditionalCostApi.getAdditionalCosts(invoiceId);
        if (data.status) {
          this.additionalCosts = data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingGetAdditionalCost = false;
      }
    },
  },
};
