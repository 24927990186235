<template>
  <div class="molecule-additional-cost-modal">
    <modal :value="isOpen" :close-on-click-backdrop="false" @close="closeModal">
      <bg-text tag="h4" size="heading-4">Biaya Tambahan</bg-text>
      <bg-text size="body-2" class="mb-24">
        Tambahkan biaya tambahan untuk tagihan ini
      </bg-text>

      <grid v-if="loading" vAlign="bottom">
        <grid-item :col="12">
          <bg-skeleton width="100%"></bg-skeleton>
        </grid-item>
        <grid-item :col="5">
          <bg-skeleton width="100%"></bg-skeleton>
        </grid-item>
        <grid-item :col="5">
          <bg-skeleton width="100%"></bg-skeleton>
        </grid-item>
      </grid>

      <ValidationObserver v-else ref="observer">
        <template v-for="(cost, index) in updatedCosts">
          <grid v-if="cost.action !== 'DELETE'" vAlign="bottom" :key="cost.id">
            <grid-item :col="12">
              <bg-field label="Jenis Biaya" class="mb-0">
                <bg-select
                  v-model="cost.cost_type"
                  :options="costTypes"
                  :disabled="!isPossibleAddCost"
                  placeholder="Pilih jenis biaya di sini"
                />
              </bg-field>
            </grid-item>

            <grid-item :col="5">
              <ValidationProvider
                :name="`Nama Biaya ${index + 1}`"
                rules="required"
                v-slot="{ errors }"
              >
                <bg-field
                  label="Nama Biaya"
                  class="mb-0"
                  :error="errors.length > 0"
                >
                  <bg-input
                    v-model="cost.cost_title"
                    :disabled="!isPossibleAddCost"
                    placeholder="Tulis nama biaya di sini"
                  />
                </bg-field>
              </ValidationProvider>
            </grid-item>

            <grid-item :col="5">
              <ValidationProvider
                :name="`Jumlah Biaya ${index + 1}`"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <bg-field
                  label="Jumlah Biaya"
                  class="mb-0"
                  :error="errors.length > 0"
                >
                  <bg-input
                    v-model="cost.cost_value"
                    prefix="Rp."
                    :disabled="!isPossibleAddCost"
                    placeholder="Contoh: 100.000"
                  />
                </bg-field>
              </ValidationProvider>
            </grid-item>

            <grid-item :col="2">
              <bg-button
                v-if="!hideDeleteButton"
                @click="handleDeleteCost(cost)"
                size="sm"
                :disabled="!isPossibleAddCost"
                variant="tertiary-naked"
                class="btn-delete"
                ><icon name="delete"
              /></bg-button>
            </grid-item>

            <grid-item v-if="index + 1 < updatedCosts.length" :col="12">
              <bg-divider />
            </grid-item>
          </grid>
        </template>

        <bg-button
          v-if="isPossibleAddCost"
          class="button-with-icon"
          variant="secondary"
          @click="addCost"
        >
          <span>Tambah Biaya</span><icon name="add-plus" size="sm" />
        </bg-button>
      </ValidationObserver>

      <template v-slot:footer>
        <div class="ta-r">
          <bg-button variant="tertiary" @click="closeModal">
            Kembali
          </bg-button>

          <bg-button
            v-if="isPossibleAddCost"
            variant="primary"
            class="ml-16"
            :loading="loadingSubmitting"
            @click="submitCost"
          >
            Simpan
          </bg-button>
        </div>
      </template>
    </modal>
    <bg-toast
      v-model="isShowToast"
      :duration="5"
      action-text="close"
      @click="closeToast"
    >
      <ul>
        <li v-for="message in messages" :key="message">- {{ message }}</li>
      </ul>
    </bg-toast>
  </div>
</template>

<script>
import {
  BgModal,
  BgText,
  BgButton,
  BgSelect,
  BgField,
  BgGrid,
  BgGridItem,
  BgInput,
  BgIcon,
  BgSkeleton,
  BgToast,
  BgDivider,
} from 'bangul-vue';
import uniqueId from 'Utils/uniqueId';
import AdditionalCostApi from '@admin_endpoints/additionalCost';

const Actions = {
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CREATE: 'CREATE',
};

export default {
  name: 'AdditionalCostModal',

  components: {
    modal: BgModal,
    BgText,
    BgButton,
    BgSelect,
    BgField,
    grid: BgGrid,
    gridItem: BgGridItem,
    BgInput,
    icon: BgIcon,
    BgSkeleton,
    BgToast,
    BgDivider,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    invoiceId: {
      type: [Number, String],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    costs: {
      type: Array,
      default: () => [],
    },
    paidStatus: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      costTypes: [
        {
          val: 'fixed',
          label: 'Biaya Tetap',
        },
        {
          val: 'other',
          label: 'Biaya Lainnya',
        },
      ],
      updatedCosts: [],
      loadingSubmitting: false,
      isShowToast: false,
      messages: [],
    };
  },

  computed: {
    isPossibleAddCost() {
      return this.paidStatus === 'unpaid';
    },
    hideDeleteButton() {
      return (
        this.updatedCosts.length === 1 &&
        this.updatedCosts[0].action === Actions.CREATE
      );
    },
  },

  watch: {
    costs(value) {
      if (value.length) {
        this.updatedCosts = this.filterEditableCost(value);
        if (!this.updatedCosts.length) {
          this.addCost();
        }
      }
    },
  },

  methods: {
    closeModal() {
      if (this.loadingSubmitting) return;

      this.$emit('update:isOpen', false);
      this.$emit('update:invoiceId', null);
      this.$emit('update:costs', []);
      this.isShowToast = false;
      this.messages = [];
    },

    closeToast() {
      this.isShowToast = false;
      this.messages = [];
    },

    filterEditableCost(costs) {
      const filteredCosts = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const cost of costs) {
        if (!['admin', 'discount_admin'].includes(cost.cost_type)) {
          filteredCosts.push({
            ...cost,
            action: Actions.UPDATE,
            additional_cost_id: cost.id,
            cost_value: `${cost.cost_value}`,
          });
        }
      }

      return filteredCosts;
    },

    addCost() {
      this.updatedCosts.push({
        action: Actions.CREATE,
        invoice_id: this.invoiceId,
        cost_value: '',
        cost_title: '',
        cost_type: 'fixed',
        unique_id: uniqueId(),
      });
    },

    handleDeleteCost(cost) {
      if (cost.action === Actions.CREATE) {
        const idx = this.updatedCosts.findIndex(
          each => each.unique_id === cost.unique_id
        );

        if (idx !== -1) {
          this.updatedCosts.splice(idx, 1);
        }
      } else if (cost.action === Actions.UPDATE) {
        const idx = this.updatedCosts.findIndex(each => each.id === cost.id);

        if (idx !== -1) {
          this.updatedCosts[idx].action = Actions.DELETE;
        }
      }
    },

    async submitCost() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        const costs = this.filterEditedCost(this.updatedCosts);

        if (!costs.length) {
          this.closeModal();
          return;
        }

        try {
          this.loadingSubmitting = true;
          const { data } = await AdditionalCostApi.postAdditionalCost({
            additional_costs: costs,
          });

          this.loadingSubmitting = false;

          if (data.status) {
            this.closeModal();
            this.$emit('action-success-submit');
          } else {
            this.messages.push('Terjadi kesalahan silahkan coba lagi.');
            this.isShowToast = true;
          }
        } catch (error) {
          let messages = ['Terjadi kesalahan silahkan coba lagi.'];
          this.loadingSubmitting = false;

          if (error?.response?.data.issue?.details) {
            const { details } = error.response.data.issue;
            messages = Object.keys(details).map(key => details[key][0]);
          }

          this.messages = messages;
          this.isShowToast = true;
        }
      }
    },

    filterEditedCost(costs) {
      const filtered = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const cost of costs) {
        if (cost.action === Actions.UPDATE && !this.anyChanges(cost)) {
          // eslint-disable-next-line no-continue
          continue;
        }

        filtered.push({ ...cost });
      }

      return filtered;
    },

    anyChanges(cost) {
      const selectedCost = this.costs.find(each => each.id === cost.id);
      if (selectedCost) {
        return this.compareChanges(cost, selectedCost)(
          'cost_value',
          'cost_title',
          'cost_type'
        );
      }

      return true;
    },

    compareChanges(data1, data2) {
      return (...keys) => keys.some(key => data1[key] !== data2[key]);
    },
  },
};
</script>

<style lang="scss" src="./AdditionalCostModal.scss"></style>
