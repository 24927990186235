<template>
  <div class="organism-header-contract-detail">
    <grid>
      <template v-if="currentState === AsyncState.FETCHING">
        <grid-item v-for="i in 3" :col="4" :key="i">
          <bg-card>
            <div>
              <bg-skeleton :height="25" width="30%" />
            </div>
            <bg-divider />
            <div>
              <bg-list-item v-for="j in 2" :key="j" class="mb-24">
                <template #default>
                  <bg-skeleton :height="20" width="150px" />
                </template>
                <template #right-content>
                  <bg-skeleton :height="20" width="100px" />
                </template>
              </bg-list-item>
            </div>
          </bg-card>
        </grid-item>
      </template>
      <template v-else-if="currentState === AsyncState.SUCCESS">
        <grid-item :col="4">
          <bg-card>
            <div class="card-title-with-tag">
              <txt size="heading-4"> Kontrak </txt>
              <txt size="label-2" italic class="grey-text">
                Update terakhir {{ contract.last_update }} oleh
                {{ contract.updater_name || 'Sistem' }}
              </txt>
              <bg-tag>{{ formatter(contract.contract_status) }}</bg-tag>
            </div>

            <bg-divider />

            <div>
              <bg-list-item class="mb-24">
                <template #default>
                  <txt size="body-1"> Booking Code </txt>
                </template>
                <template #right-content>
                  <txt size="body-2">{{ contract.booking_code }}</txt>
                </template>
              </bg-list-item>

              <bg-list-item class="mb-24">
                <template #default>
                  <txt size="body-1"> Check-in </txt>
                </template>
                <template #right-content>
                  <txt size="body-2">{{ contract.check_in_date }}</txt>
                </template>
              </bg-list-item>

              <bg-list-item>
                <template #default>
                  <txt size="body-1"> Check-out </txt>
                </template>
                <template #right-content>
                  <txt size="body-2">{{ contract.check_out_date }}</txt>
                </template>
              </bg-list-item>
            </div>
          </bg-card>
        </grid-item>

        <grid-item :col="4">
          <bg-card>
            <div>
              <txt size="heading-4"> Penyewa </txt>
            </div>

            <bg-divider />

            <div>
              <bg-list-item class="mb-24">
                <template #default>
                  <txt size="body-1"> Nama </txt>
                </template>
                <template #right-content>
                  <txt size="body-2">{{ tenant.name }}</txt>
                </template>
              </bg-list-item>

              <bg-list-item>
                <template #default>
                  <txt size="body-1"> Nomor HP </txt>
                </template>
                <template #right-content>
                  <txt size="body-2">{{ tenant.phone_number }}</txt>
                </template>
              </bg-list-item>
            </div>
          </bg-card>
        </grid-item>

        <grid-item :col="4">
          <bg-card>
            <div>
              <txt size="heading-4"> Deposit </txt>
            </div>

            <bg-divider />

            <div>
              <bg-list-item class="mb-24">
                <template #default>
                  <txt size="body-1"> Deposit </txt>
                </template>
                <template #right-content>
                  <txt size="body-2">{{ deposit.total_deposit | rupiah }}</txt>
                </template>
              </bg-list-item>
            </div>
          </bg-card>
        </grid-item>
      </template>
    </grid>
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgCard,
  BgText,
  BgTag,
  BgDivider,
  BgListItem,
  BgSkeleton,
} from 'bangul-vue';
import RoomAllotmentApi from '@admin_endpoints/room-allotment';
import { rupiahFormatter } from 'Utils/formatter';
import formatterContractStatus from './formatter/contract-status.json';

const AsyncState = {
  INITIAL: 'initial',
  FETCHING: 'fetching',
  SUCCESS: 'success',
  ERROR: 'error',
};

export default {
  name: 'HeaderContractDetail',

  components: {
    grid: BgGrid,
    gridItem: BgGridItem,
    BgCard,
    txt: BgText,
    BgTag,
    BgDivider,
    BgListItem,
    BgSkeleton,
  },

  data() {
    return {
      contract: {},
      tenant: {},
      deposit: {},
      AsyncState,
      currentState: AsyncState.INITIAL,
    };
  },

  computed: {
    contractId() {
      return this.$route.params.contractId;
    },
  },

  created() {
    this.fetchContractHeaderInfo();
  },

  filters: {
    rupiah: rupiahFormatter,
  },

  methods: {
    formatter(value) {
      const findValue = formatterContractStatus.find(
        item => item.value === value
      );

      return findValue?.text || value;
    },

    async fetchContractHeaderInfo() {
      this.currentState = AsyncState.FETCHING;
      try {
        const {
          data: { status, data },
        } = await RoomAllotmentApi.getContractHeaderInfo(this.contractId);

        if (status) {
          this.contract = data.contract;
          this.tenant = data.tenant;
          this.deposit = data.deposit;
          this.currentState = AsyncState.SUCCESS;
        } else {
          this.currentState = AsyncState.ERROR;
        }
      } catch (error) {
        this.currentState = AsyncState.ERROR;
      }
    },
  },
};
</script>

<style lang="scss" src="./HeaderContractDetail.scss" scoped></style>
