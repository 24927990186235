<template>
  <div class="organism-contract-detail-table">
    <div class="table-layout-detail">
      <div class="mb-32">
        <txt size="heading-4">Daftar Tagihan</txt>
      </div>

      <div class="filter">
        <div class="filter-left">
          <bg-tag
            size="lg"
            @click="emitApplyFilter('paid_status', '')"
            :active="params.paid_status === ''"
            >Semua</bg-tag
          >
          <bg-tag
            size="lg"
            @click="emitApplyFilter('paid_status', 'paid')"
            :active="params.paid_status === 'paid'"
            >Dibayar</bg-tag
          >
          <bg-tag
            size="lg"
            @click="emitApplyFilter('paid_status', 'unpaid')"
            :active="params.paid_status === 'unpaid'"
            >Belum Dibayar</bg-tag
          >
          <bg-tag
            size="lg"
            @click="emitApplyFilter('paid_status', 'paid_late')"
            :active="params.paid_status === 'paid_late'"
            >Telat Bayar</bg-tag
          >
        </div>
        <div class="filter-right">
          <search-bar
            v-model="search"
            type="text"
            placeholder="Cari berdasarkan no. invoice"
            size="md"
            @keyup.enter="emitApplyFilter('search', search)"
            :showClearTextIcon="false"
          />
        </div>
      </div>

      <div>
        <skeleton v-if="loadingInvoiceList" :width="'165px'" :height="24" />
        <txt v-else v-show="invoiceList.length" size="body-2" italic
          >Total data tagihan: {{ pagination.total_items }}</txt
        >
      </div>

      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>
                <txt size="title-5">No. Invoice</txt>
              </th>
              <th>
                <txt size="title-5">Status Invoice</txt>
              </th>
              <th>
                <txt size="title-5">Promo</txt>
              </th>
              <th>
                <txt size="title-5">Jumlah Tagihan</txt>
              </th>
              <th>
                <txt size="title-5">Waktu Penagihan</txt>
              </th>
              <th>
                <txt size="title-5">Jatuh Tempo</txt>
                <div @click="emitApplyFilter('sort_dir')">
                  <icon :name="ascending ? 'down' : 'up'" size="sm" />
                </div>
              </th>
              <th>
                <txt size="title-5">Biaya Tambahan</txt>
              </th>
              <th>
                <txt size="title-5">Catatan</txt>
              </th>
              <th>
                <txt size="title-5">Action</txt>
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-if="loadingInvoiceList">
              <tr v-for="i in 3" :key="i">
                <td v-for="i in 9" :key="i">
                  <skeleton :height="20" width="100%" />
                </td>
              </tr>
            </template>

            <template v-else>
              <tr
                v-for="(item, index) in invoiceList"
                :key="`constract-detail-item-${index}`"
              >
                <td>
                  {{ item.invoiceNo }}
                </td>
                <td>
                  <label-rainbow
                    :color="item.invoiceStatus | labelFormatter('color')"
                    >{{
                      item.invoiceStatus | labelFormatter('text')
                    }}</label-rainbow
                  >
                </td>
                <td>
                  <div
                    :class="item.voucherAmount ? 'clickable' : 'not-clickable'"
                    @click="handlePromoModal(item.invoiceId)"
                  >
                    {{
                      item.voucherAmount | voucherFormatter(item.voucherType)
                    }}
                  </div>
                </td>
                <td>
                  {{ item.bill | rupiahFormatter }}
                </td>
                <td>
                  Per
                  <br />
                  {{ item.period | periodFormatter() }}
                </td>
                <td>
                  {{ item.dueDate | dateFormatterToDisplay() }}
                </td>
                <td>
                  <div
                    :class="item.additionalCost ? 'clickable' : 'not-clickable'"
                    @click="additionalCost(item.invoiceId)"
                  >
                    {{ item.additionalCost | rupiahFormatter }}
                  </div>
                </td>
                <td>
                  <div
                    :class="item.note ? 'clickable' : 'not-clickable'"
                    @click="catatanModal(index)"
                  >
                    {{ item.note | truncateEllipsis }}
                  </div>
                </td>
                <td>
                  <div
                    v-if="accessEditAdditionalfee || accessEditNotes"
                    class="option-detail"
                    @click="handleOpenOptions(item.showOptions, index)"
                  >
                    <icon name="more-vertical" title="edit note" />
                  </div>
                  <div class="options" v-if="item.showOptions">
                    <bg-list-item-icon
                      v-if="accessEditAdditionalfee"
                      icon="price-setting"
                      clickable
                      @click="handleOpenAddAdditionalCost(item, index)"
                      >Biaya Tambahan</bg-list-item-icon
                    >
                    <bg-list-item-icon
                      v-if="accessEditNotes"
                      icon="edit"
                      clickable
                      @click="handleOpenModalNote(item.invoiceId, index)"
                      >Catatan</bg-list-item-icon
                    >
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <template v-if="totalPagination > 1">
        <div v-if="loadingInvoiceList" class="ta-c">
          <skeleton :width="'175px'" :height="24" />
        </div>
        <bg-pagination
          v-else
          :value="activePagination"
          :pageTotal="totalPagination"
          @click="onPageChanged"
        />
      </template>

      <div class="no-data" v-if="!invoiceList.length && !loadingInvoiceList">
        <txt size="title-2">Data Tidak Ditemukan</txt>
        <txt size="body-2"
          >Data tidak ditemukan di filter atau kata kunci yang Anda
          gunakan.</txt
        >
      </div>
    </div>

    <modal v-model="showPromoModal" @close="closePromoModal">
      <div v-if="promoDetail.isLoading">
        <skeleton :width="'100%'" :height="24" style="margin-bottom: 8px" />
        <skeleton :width="'100%'" :height="24" style="margin-bottom: 8px" />
        <skeleton :width="'50%'" :height="24" />
      </div>
      <div v-else class="promo-modal">
        <txt size="heading-4" class="mb-16">{{
          promoDetail.content.title
        }}</txt>
        <txt class="mb-24" size="body-2"
          >Periode : {{ promoDetail.content.startDate
          }}{{ promoDetail.content.endDate }} | Kode Promo :
          <strong>{{ promoDetail.content.code }}</strong></txt
        >
        <txt class="mb-8"><strong>Term and Condition</strong></txt>
        <div class="html-styling" v-html="promoDetail.content.tnc" />
      </div>
    </modal>

    <modal v-model="showCatatanModal" desktop-size="sm">
      <txt class="mb-16" size="heading-5">Catatan</txt>
      <txt size="body-2" class="ws-pre-wrap mb-32">{{ noteUpdate.note }}</txt>
      <ul class="left-right-flex">
        <li>
          <strong>Update terakhir oleh:</strong>
          <br />
          {{ noteUpdate.by }}
        </li>
        <li>
          <strong>{{ noteUpdate.time }}</strong>
        </li>
      </ul>
    </modal>

    <modal
      :value="showAdditionalCost"
      :close-on-click-backdrop="false"
      desktop-size="sm"
      @close="handleCloseAdditionalCostInfo"
    >
      <txt class="mb-16" size="heading-4">Biaya Tambahan</txt>
      <ul class="left-right-flex">
        <template v-if="loadingGetAdditionalCost">
          <Fragment v-for="i in 3" :key="i">
            <li><skeleton :height="20" :width="120" /></li>
            <li><skeleton :height="20" :width="70" /></li>
          </Fragment>
        </template>
        <template v-else>
          <Fragment v-for="cost in additionalCosts" :key="cost.id">
            <li>
              <strong>{{ cost.cost_title }}</strong>
            </li>
            <li>{{ cost.cost_value | rupiahFormatter }}</li>
          </Fragment>
        </template>
      </ul>
    </modal>

    <modal v-model="showAddAdditionalCost">
      <txt class="mb-16" size="heading-4">Tambah Biaya Tambahan</txt>
      <txt size="body-2">Tambahkan biaya tambahan untuk tagihan ini</txt>
    </modal>

    <note-modal :is-open.sync="isModalNoteOpen" />

    <additional-cost-modal
      :loading="loadingGetAdditionalCost"
      :costs.sync="additionalCosts"
      :invoice-id.sync="selectedInvoiceId"
      :paid-status.sync="selectedPaidStatus"
      :is-open.sync="isModalAdditionalCostOpen"
      @action-success-submit="reloadInvoiceList"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

import {
  rupiahFormatter,
  truncateEllipsis,
  dateFormatterToDisplay,
} from 'Utils/formatter';

import {
  BgText,
  BgIcon,
  BgLabelRainbow,
  BgModal,
  BgListItemIcon,
  BgPagination,
  BgTag,
  BgSearchBar,
  BgSkeleton,
} from 'bangul-vue';

import roomAllotmentApi from '@admin_endpoints/room-allotment';
import NoteModal from '@admin_molecules/NoteModal';
import AdditionalCostModal, {
  additionalCostMixin,
} from '@admin_molecules/AdditionalCostModal';
import Fragment from '@/components/Fragment';
import formatterPeriod from './formatter/period.json';
import formatterInvoiceStatus from './formatter/invoice-status.json';

export default {
  name: 'ContractDetailTable',

  components: {
    txt: BgText,
    icon: BgIcon,
    labelRainbow: BgLabelRainbow,
    modal: BgModal,
    BgListItemIcon,
    BgPagination,
    BgTag,
    searchBar: BgSearchBar,
    skeleton: BgSkeleton,
    NoteModal,
    AdditionalCostModal,
    Fragment,
  },

  filters: {
    dateFormatterToDisplay(value) {
      if (value) {
        return dateFormatterToDisplay(value);
      }
      return '-';
    },

    rupiahFormatter(value) {
      if (value) {
        return rupiahFormatter(value);
      }
      return '-';
    },

    truncateEllipsis(value) {
      if (value) {
        return truncateEllipsis(value, 50);
      }
      return '-';
    },

    voucherFormatter(value, type) {
      if (value) {
        if (type === 'amount') {
          return rupiahFormatter(value);
        }

        if (type === 'percentage') {
          return `${value}%`;
        }
      } else {
        return '-';
      }
    },

    periodFormatter(value) {
      const array = [...formatterPeriod];

      const findValue = array.find(item => item.value === value);

      return findValue?.text || value;
    },

    labelFormatter(value, type) {
      const array = [...formatterInvoiceStatus];

      const findValue = array.find(item => item.value === value);

      if (type === 'text') {
        return findValue.text || value;
      }

      if (type === 'color') {
        return findValue.color || 'white';
      }
    },
  },

  mixins: [additionalCostMixin],

  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
    invoiceList: {
      type: Array,
      default: () => [],
      /* Is a list of these objects:
				invoiceId
				invoiceNo
				invoiceStatus
				voucherAmount
				voucherType
				bill
				period
				dueDate
				additionalCost
				note
				noteUpdatedBy
				noteUpdatedTime
				showOptions
			*/
    },
    loadingInvoiceList: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showPromoModal: false,
      showNoteModal: false,
      showAdditionalCost: false,
      showAddAdditionalCost: false,
      showCatatanModal: false,
      ascending: true,
      search: '',
      isModalNoteOpen: false,
      isModalAdditionalCostOpen: false,
      noteUpdate: {
        note: '',
        by: '',
        time: '',
      },
      promoDetail: {
        isLoading: false,
        content: {},
      },
    };
  },

  computed: {
    accessEditNotes() {
      return this.$store.getters.xCheckUserPermission('edit-notes');
    },
    accessEditAdditionalfee() {
      return this.$store.getters.xCheckUserPermission('edit-additionalfee');
    },

    totalPagination() {
      return Math.ceil(this.pagination.total_items / this.pagination.limit);
    },

    activePagination() {
      return this.pagination?.current_page;
    },
  },

  mounted() {
    window.addEventListener('click', this.blurListener);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.blurListener);
  },

  methods: {
    async getInvoicePromo(invoiceId) {
      let result = {};
      try {
        result = await roomAllotmentApi.getPromoVoucherDetail(invoiceId);
      } catch (error) {
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async handleOpenModalNote(invoiceId, index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.invoiceList[index].showOptions = false;
      this.isModalNoteOpen = true;
      await this.$store.dispatch('note/getCurrentNote', invoiceId);
    },

    additionalCost(invoiceId) {
      this.showAdditionalCost = true;
      this.fetchingAdditionalCostData(invoiceId);
    },

    handleCloseAdditionalCostInfo() {
      this.showAdditionalCost = false;
      this.additionalCosts = [];
    },

    getSearchValue() {
      alert('get search value');
    },

    sortDueDate() {
      alert('testing');
      this.descending = !this.descending;
    },

    handleOpenOptions(show, index) {
      if (!show) {
        this.invoiceList.forEach(e => {
          e.showOptions = false;
        });
        // eslint-disable-next-line vue/no-mutating-props
        this.invoiceList[index].showOptions = true;
      } else {
        this.invoiceList.forEach(e => {
          e.showOptions = false;
        });
      }
    },

    catatanModal(index) {
      this.showCatatanModal = true;
      const currentData = this.invoiceList[index];

      this.noteUpdate.note = currentData.note;
      this.noteUpdate.by = currentData.noteUpdatedBy;
      this.noteUpdate.time = dayjs(currentData.noteUpdatedTime).format(
        'DD MMM YYYY'
      );
    },

    async handlePromoModal(invoiceId) {
      this.showPromoModal = true;
      this.promoDetail.isLoading = true;

      const response = await this.getInvoicePromo(invoiceId);
      const content = response.data.data;

      const formatted = {
        code: content.code,
        title: content.title,
        startDate: dayjs(content.start_date).format('DD MMM YYYY'),
        endDate: content.end_date
          ? `- ${dayjs(content.end_date).format('DD MMM YYYY')}`
          : '',
        tnc: content.tnc,
      };

      this.promoDetail.content = formatted;

      this.promoDetail.isLoading = false;
    },

    closePromoModal() {
      this.promoDetail.content = {};
    },

    onPageChanged(page) {
      const offset = (page - 1) * this.pagination.limit;
      this.emitApplyFilter('offset', offset);
    },

    emitApplyFilter(type, value) {
      if (type === 'sort_dir') {
        this.ascending = !this.ascending;
        this.$nextTick();
        this.$emit('emit-apply-filter', {
          type,
          value: this.ascending ? 'asc' : 'desc',
        });
      } else if (type === 'search') {
        this.search = this.search.trim();
        this.$nextTick();
        this.$emit('emit-apply-filter', { type, value: this.search });
      } else {
        this.$emit('emit-apply-filter', { type, value });
      }
    },

    handleOpenAddAdditionalCost(item, index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.invoiceList[index].showOptions = false;
      this.openModalAddionalCost();
      this.selectedInvoiceId = item.invoiceId;
      this.selectedPaidStatus = item.invoiceStatus;
      this.fetchingAdditionalCostData(item.invoiceId);
    },

    reloadInvoiceList() {
      this.$emit('reload-invoice-list');
    },

    blurListener(e) {
      const isThisComponent =
        e.target.parentNode?._prevClass === 'option-detail' ||
        e.target._prevClass === 'option-detail';

      if (!isThisComponent) {
        this.invoiceList.forEach(item => {
          item.showOptions = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./ContractDetailTable.scss" scoped></style>
